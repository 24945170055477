import * as React from "react"
import { FunctionComponent } from "react"
import {Wrap} from "./Error404Page.css"

export type Props = {}
export type Functions = {}

export type AllProps = Props & Functions;

export const Error404Page: FunctionComponent<AllProps> = ({}) =>
  <Wrap>404</Wrap>

export default Error404Page
